import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ScrollToTop from "./Component/Scrollup/ScrollUp";
import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/About-us/About";
import Services from "./Pages/Service/Service";
import FaqsContent from "./Pages/Faqs/Faqs";
import BlogContent from "./Pages/Blog/Blog";
import ArticlePage1 from "./Pages/Article/ArticlePage1";
import ArticlePage2 from "./Pages/Article/ArticlePage2";
import ArticlePage3 from "./Pages/Article/ArticlePage3";
import HomeHeader from "./Component/HeaderHome/HeaderHomeWeb";
import HomeHeaderMobile from "./Component/HeaderHome/HeaderHomeMobile";
import Header from "./Component/Header/HeaderWeb";
import HeaderMobile from "./Component/Header/HeaderMobile";
import Footer from "./Component/Footer/FooterWeb";
import FooterMobile from "./Component/Footer/FooterMobile";
import KellerContent from "./Pages/Branch/Keller/Keller";
import GrapevineContent from "./Pages/Branch/Grapevine/Grapevine";
import GrandPrairieContent from "./Pages/Branch/Grand_Prairie/Grand_Prairie";
import WashClubContent from "./Pages/WashClub/WashClub";
import FeedBackContent from "./Pages/Feedback/FeedBack";
import ContactUsContent from "./Pages/Contact-us/ContactUs";
import LocationsNew from "./Pages/Location/LocationsNew";
const routes = [
  { path: "/", component: <Home /> },
  { path: "/About-us", component: <AboutUs /> },
  { path: "/Services", component: <Services /> },
  { path: "/Faqs", component: <FaqsContent /> },
  { path: "/Blog", component: <BlogContent /> },
  { path: "/Self-Service", component: <ArticlePage1 /> },
  { path: "/A-Dog-Grooming", component: <ArticlePage2 /> },
  { path: "/great-car-wash", component: <ArticlePage3 /> },
  { path: "/Locations", component: <LocationsNew /> },
  { path: "/Keller", component: <KellerContent /> },
  { path: "/Grapevine", component: <GrapevineContent /> },
  { path: "/GrandPrairie", component: <GrandPrairieContent /> },
  { path: "/WashClub", component: <WashClubContent /> },
  { path: "/FeedBack", component: <FeedBackContent /> },
  { path: "/Contact-us", component: <ContactUsContent /> },
];

export default function RoutePages() {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const HeaderComponent = isHomePage ? (isSmallScreen ? HeaderMobile : HomeHeader) : isSmallScreen ? HeaderMobile : Header;

  const FooterComponent = isSmallScreen ? FooterMobile : Footer;

  return (
    <>
      <HeaderComponent />
      <ScrollToTop />
      <Routes>
        {routes.map(({ path, component }, index) => (
          <Route key={index} path={path} element={component} />
        ))}
      </Routes>
      <FooterComponent />
    </>
  );
}
