import React from "react";
import "./BlogBox.css";
import { Link } from "react-router-dom";
import RightIcon from "../../../svg/Home/RightIcon.svg";
export default function Blog({ BlogData }) {
  return (
    <div className="Blog_Box_Main">
      {BlogData.map((Blog, index) => (
        <>
          <div className="Blog_Box_Container">
            {/*  */}
            <div className="BlogBoxP1">
              <Link to={Blog.ImgUrl}>
                <img src={Blog.Image} alt={Blog.Alt} className="BlogBoxP1Img" />
              </Link>
            </div>
            {/*  */}
            <div className="BlogBoxP2">{Blog.Date}</div>
            {/*  */}
            <div className="BlogBoxP3">{Blog.Head}</div>

            {/*  */}
            <div className="BlogBoxP4">{Blog.Text}</div>

            {/*  */}
          </div>
          <div className="BlogBoxP5">
            <Link to={Blog.Button}>
              Read More <img src={RightIcon} alt="" className="BlogBoxP5Icon" />
            </Link>
          </div>
        </>
      ))}
    </div>
  );
}
