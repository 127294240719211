import React, { useEffect, useState } from "react";
import "./HeaderMob.css";
import HeaderMobileLogoScrolled from "../../Assets/svg/Header/LogoScroll.svg";
import ButtonIcon from "../../Assets/svg/Header/Button_Icon.svg";
import WashClub from "../../Assets/svg/Header/WashClub.svg";
import HeaderMobIcon from "../../Assets/svg/Header/HeaderHomeMobIcon.svg";
import OpenIconBlack from "../../Assets/svg/Header/DropDownMenuMobOpenBlack.svg";
import CloseIconBlack from "../../Assets/svg/Header/DropDownMenuMobOpenBlack.svg";
import { Link, useLocation } from "react-router-dom";

export default function HeaderMobile() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const isActive = (path) => currentPath === path;

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = window.innerHeight * 0.35;
      setIsScrolled(window.scrollY > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [currentPath]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <div className="HeaderHomeMobileMain">
      <div className={`HeaderMob_Absoulte ${isScrolled ? "scrolled" : ""}`}>
        <div className="HeaderMob">
          <Link to="/">
            <img src={HeaderMobileLogoScrolled} alt="" className="HeaderMobImg" />
          </Link>
          <div className="HeaderMobRoute">
            <div>
              <Link to="/Locations">
                <img src={HeaderMobIcon} alt="" className="HeaderHomeMobIcon" />
              </Link>
            </div>
            <button onClick={toggleDropdown} className="DropdownToggleButton">
              <img src={isDropdownOpen ? CloseIconBlack : OpenIconBlack} alt="Toggle Menu" className="toggleDropdownHomeMob" />
            </button>
            {isDropdownOpen && (
              <div className="DropdownMenu">
                <Link to="/" className={`${isActive("/") ? "active_link_Mob" : ""} ${isScrolled ? "scrolled_link_Mob" : ""}`}>
                  Home
                </Link>
                <Link to="About-us" className={`${isActive("/About-us") ? "active_link_Mob" : ""} ${isScrolled ? "scrolled_link_Mob" : ""}`}>
                  About Us
                </Link>
                <Link to="Services" className={`${isActive("/Services") ? "active_link_Mob" : ""} ${isScrolled ? "scrolled_link_Mob" : ""}`}>
                  Services
                </Link>
                <Link to="/faqs" className={`${isActive("/faqs") ? "active_link_Mob" : ""} ${isScrolled ? "scrolled_link_Mob" : ""}`}>
                  FAQs
                </Link>
                <Link to="/blog" className={`${isActive("/blog") ? "active_link_Mob" : ""} ${isScrolled ? "scrolled_link_Mob" : ""}`}>
                  Blog
                </Link>
                <Link to="/washclub" className={`${isActive("/washclub") ? "active_link_Mob" : ""} ${isScrolled ? "scrolled_link_Mob" : ""}`}>
                  <div className="WashClubContMob">
                    WashClub <img src={WashClub} alt="" className="WashClubSvgMob" />
                  </div>
                </Link>
                <Link to="/Contact-us" className={`${isActive("/Contact-us") ? "active_link_Mob" : ""} ${isScrolled ? "scrolled_link_Mob" : ""}`}>
                  Contact us
                </Link>
                <Link to="/Locations">
                  <button className="HeaderMobButton">
                    <img src={ButtonIcon} alt="" className="HeaderMobButtonImg" />
                    Our Locations
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
