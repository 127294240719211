import React, { useRef, useState, useEffect } from "react";
import "./HeroHome.css";
import ComingVideoWeb from "../../../Assets/images/Home/HeroHomeSider1.jpg";
import ComingVideoMobile from "../../../Assets/images/Home/HeroHomeSider1.jpg";
import FeatherIcon from "../../../Assets/svg/Home/Feather_Icon.svg";
import EllipseIcon from "../../../Assets/svg/Home/Ellipse_Icon.svg";
import HeroHomeSider1 from "../../../Assets/images/Home/HeroHomeSider1.jpg";
import HeroHomeSider2 from "../../../Assets/images/Home/HeroHomeSider2.jpg";
import HeroHomeSider3 from "../../../Assets/images/Home/HeroHomeSider3.jpg";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

export default function HeroHome() {
  const videoParentRef = useRef();
  const videoRef = useRef(null);
  const [shouldUseImage, setShouldUseImage] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [currentImage, setCurrentImage] = useState(0);
  const images = [HeroHomeSider1, HeroHomeSider2, HeroHomeSider3];

  const altTexts = ["K car wash service", "GP car wash service", "GV car wash service"];

  useEffect(() => {
    const intervalRef = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalRef);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isSafari() && videoRef.current) {
      const player = videoRef.current;

      if (player) {
        player.controls = false;
        player.playsInline = true;
        player.muted = true;
        player.setAttribute("muted", "");
        player.autoplay = true;

        setTimeout(() => {
          const promise = player.play();
          if (promise && promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, [isMobile]);

  const videoSrc = isMobile ? ComingVideoMobile : ComingVideoWeb;

  return shouldUseImage ? (
    <img src={videoSrc} alt="Video" />
  ) : (
    <div className="Home" ref={videoParentRef}>
      {/*  */}
      {/* <video ref={videoRef} loop muted autoPlay playsInline preload="metadata" className="video-background">
        <source src={videoSrc} type="video/mp4" />
      </video> */}
      {/*  */}
      {images.map((image, index) => (
        <img key={index} src={image} alt={altTexts[index]} className={`video-background ${currentImage === index ? "active" : ""}`} />
      ))}
      <div className="image-overlay"></div>

      <div className="HomeS1V2">
        <div className="HomeS1V2P1">Enjoy A Crystal Clear Wash</div>
        <div className="HomeS1V2P2">
          Experience the best self-service <br />
          car wash journey .
        </div>
        <div className="HomeS1V2P3">
          {/* <div className="HomeS1V2P3T1">
            <Link to="/About-us">
              Explore Our Story <img src={FeatherIcon} alt="" />
            </Link>
          </div> */}
          <div className="HomeS1V2P3T2">
            <div className="scrolling-content">
              Self-Service Carwash <span></span> <img src={EllipseIcon} alt="" /> <span></span> Touch-Free Automatic Wash <span></span>
              <img src={EllipseIcon} alt="" /> <span></span> Vacuums <span></span>
              <img src={EllipseIcon} alt="" /> <span></span>Pet Wash (Grapevine Location) <span></span> <img src={EllipseIcon} alt="" /> <span></span> Pet Park
              (Grapevine Location)<span></span> <img src={EllipseIcon} alt="" /> <span></span>
              {/* Duplicate the content here */}
              Self-Service Carwash <span></span> <img src={EllipseIcon} alt="" /> <span></span> Touch-Free Automatic Wash <span></span>
              <img src={EllipseIcon} alt="" /> <span></span> Vacuums <span></span>
              <img src={EllipseIcon} alt="" /> <span></span>Pet Wash (Grapevine Location) <span></span> <img src={EllipseIcon} alt="" /> <span></span> Pet Park
              (Grapevine Location)<span></span> <img src={EllipseIcon} alt="" /> <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
