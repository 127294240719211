import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Service.css";
import ServicesS1Img from "../../Assets/images/Services/ServicesS1.jpg";
import ServicesSlider from "../../Assets/hooks/Reuse_Component/ServicesSlider/ServicesSlider";
import { Link } from "react-router-dom";
import HomeS3Right from "../../Assets/svg/Home/HomeS3Right.svg";
import HomeS3First from "../../Assets/images/Home/HomeS3First.jpg";
import HomeS3FirstMob from "../../Assets/images/Home/HomeS3FirstMob.jpg";
import HomeS3Second from "../../Assets/images/Home/HomeS3Second.jpg";
import HomeS3SecondMob from "../../Assets/images/Home/HomeS3SecondMob.jpg";
import HomeS3Third from "../../Assets/images/Home/HomeS3Third.jpg";
import HomeS3ThirdMob from "../../Assets/images/Home/HomeS3ThirdMob.jpg";
import AboutRight from "../../Assets/svg/Home/HomeS3Right.svg";
import ServicesSlideImg1 from "../../Assets/images/Services/Services_Slider_Image1.jpg";
import ServicesSlideImg2 from "../../Assets/images/Services/Services_Slider_Image2.jpg";
import ServicesSlideImg3 from "../../Assets/images/Services/Services_Slider_Image3.jpg";
import ServicesSlideImg4 from "../../Assets/images/Services/Services_Slider_Image4.jpg";
import ServicesSlideImg5 from "../../Assets/images/Services/Services_Slider_Image5.jpg";
import ServicesSlideImg6 from "../../Assets/images/Services/Services_Slider_Image6.jpg";
import ServicesSlideImg7 from "../../Assets/images/Services/Services_Slider_Image7.jpg";
import ServicesSlideImg8 from "../../Assets/images/Services/Services_Slider_Image8.jpg";
import ServicesSlideImg9 from "../../Assets/images/Services/Services_Slider_Image9.jpg";
import ServicesSlideImg10 from "../../Assets/images/Services/Services_Slider_Image10.jpg";
import ServicesSlideImg11 from "../../Assets/images/Services/Services_Slider_Image11.jpg";
import ServicesS1NewP2Img1 from "../../Assets/images/Home/HomeS4Img1.jpg";
import ServicesS1NewP2Img2 from "../../Assets/images/Home/HomeS4Img2.jpg";
import ServicesS1NewP2Img3 from "../../Assets/images/Home/HomeS4Img4.jpg";
import ServicesS1NewP2Img4 from "../../Assets/images/Home/HomeS4Img5.jpg";
import ServicesS1NewP2Img5 from "../../Assets/images/Home/HomeS4Img3.jpg";
import AboutS2Img from "../../Assets/svg/About/AboutS2P2.svg";
import useIsMobile from "../../Assets/hooks/Reuse_Component/IsMobile/IsMobile";

export default function Services() {
  return (
    <>
      <div className="Services_Container">
        <ServiceS1 />
        <ServicesS1New />
        <ServiceS2 />
        <ServiceS3 />
        <ServiceS4 />
      </div>
    </>
  );
}

// Services S1
function ServiceS1() {
  return (
    <>
      <div className="ServicesS1">
        <div className="ServicesS1Com">
          <div className="ServicesS1P1">
            <img src={ServicesS1Img} alt="srv car wash center vacuum" className="ServicesS3P1Img" />
          </div>
          <div className="ServicesS1P2">
            <div className="ServicesS1P2V1">Always gleaming & ready</div>
            <div className="ServicesS1P2V2">
              Wash<span className="SpanBiggerMore">,</span> Dry & Go.
            </div>
            <div className="ServicesS1P2V3">
              Customize your car care experience with our <br /> adaptable services. <br /> <br />
              Our self-service bays<span className="SpanBigger">,</span> touch-free automatic<span className="SpanBigger">,</span> and vacuums are designed to
              meet your cleaning needs. <br />
              <br /> Don’t forget your furry friend as we also offer pet-wash bays and and a pet park at our Grapevine location.
            </div>
          </div>
        </div>
      </div>
      {/* for Mob */}

      <div className="ServicesS1 ServicesS1Mob">
        <div className="ServicesS1P2">
          <div className="ServicesS1P2V1">Always gleaming & ready</div>
          <div className="ServicesS1P2V2">
            Wash<span className="SpanBiggerMore">,</span> Dry & Go.
          </div>
          <div className="ServicesS1P1">
            <img src={ServicesS1Img} alt="srv car wash center vacuum" className="ServicesS3P1Img" />
          </div>
          <div className="ServicesS1P2V3">
            Customize your car care experience with our adaptable services. <br /> <br /> Our self-service bays<span className="SpanBigger">,</span> touch-free
            automatic<span className="SpanBigger">,</span> and vacuums are designed to meet your cleaning needs. <br />
            <br /> Don’t forget your furry friend as we also offer pet-wash bays and and a pet park at our Grapevine location.
          </div>
        </div>
      </div>
    </>
  );
}

// Services S1 New

function ServicesS1New() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      {/*  */}
      {/*  */}
      <div className="ServicesS1New ServicesS1NewWeb" id="SelfService">
        <div className="ServicesS1NewP1">
          <div className="ServicesS1NewP1V1">Why Choose Wash Your Stuff</div>
          <div className="ServicesS1NewP1V2">More Than Just a Car Wash</div>
        </div>
        {/*  */}
        <div className="ServicesS1NewP2">
          <div className="ServicesS1NewP2V1">
            <img src={ServicesS1NewP2Img1} alt="" className="ServicesS1NewP2V1Img ImgLeft" />
          </div>
          <div className="ServicesS1NewP2V2 TextRight">
            <div className="ServicesS1NewP2V2T1">Self-Service wash</div>
            <div className="ServicesS1NewP2V2T2" id="TouchFree">
              Our self-service bays are ideal for those who want to take charge of their cleaning. <br />
              You can wash your car<span className="SpanBigger">,</span> trucks<span className="SpanBigger">,</span> bikes<span className="SpanBigger">,</span>{" "}
              mats<span className="SpanBigger">,</span> RV’s<span className="SpanBigger">,</span> boats and more. <br />
              And don't forget to use our air blowers for a spotless finish.
            </div>
          </div>
        </div>
        {/*  */}
        {/*  */}
        {/*  */}
        <div className="ServicesS1NewP2">
          <div className="ServicesS1NewP2V2">
            <div className="ServicesS1NewP2V2T1">Touch-Free Automatic Wash</div>
            <div className="ServicesS1NewP2V2T2">
              Touch-free car washes provide a safe and gentle way to clean your vehicle. Unlike traditional friction tunnel washes
              <span className="SpanBigger" id="PetPays">
                ,
              </span>{" "}
              we only use specialized chemicals and powerful water pressure to minimize the risk of paint finish damage.
            </div>
          </div>
          <div className="ServicesS1NewP2V1">
            <img src={ServicesS1NewP2Img2} alt="" className="ServicesS1NewP2V1Img" />
          </div>
        </div>

        {/*  */}
        {/*  */}
        {/*  */}
        <div className="ServicesS1NewP2">
          <div className="ServicesS1NewP2V1">
            <img src={ServicesS1NewP2Img5} alt="" className="ServicesS1NewP2V1Img ImgLeft" />
          </div>
          <div className="ServicesS1NewP2V2 TextRight">
            <div className="ServicesS1NewP2V2T1">Spoil Your Furry Friend</div>
            <div className="ServicesS1NewP2V2T2" id="PetPark">
              And don't forget to let your furry friend play in our complimentary pet playground before or after their bath!
            </div>
          </div>
        </div>
        {/*  */}
        {/*  */}
        {/*  */}
        <div className="ServicesS1NewP2">
          <div className="ServicesS1NewP2V2">
            <div className="ServicesS1NewP2V2T1">Pamper Your Pets</div>
            <div className="ServicesS1NewP2V2T2" id="Vacuums">
              Pamper your pet at our pet wash bays<span className="SpanBigger">,</span> featuring pet-friendly tubs<span className="SpanBigger">,</span> warm
              water<span className="SpanBigger">,</span> soap<span className="SpanBigger">,</span> conditioner<span className="SpanBigger">,</span> <br />{" "}
              grooming vacuums<span className="SpanBigger">,</span> and hair dryers.
            </div>
          </div>
          <div className="ServicesS1NewP2V1">
            <img src={ServicesS1NewP2Img3} alt="" className="ServicesS1NewP2V1Img " />
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="ServicesS1NewP2">
          <div className="ServicesS1NewP2V1">
            <img src={ServicesS1NewP2Img4} alt="" className="ServicesS1NewP2V1Img ImgLeft" />
          </div>
          <div className="ServicesS1NewP2V2 TextRight">
            <div className="ServicesS1NewP2V2T1">Vacuums & Vending Machines</div>
            <div className="ServicesS1NewP2V2T2" id="Vacuums">
              Get superior car cleaning with our powerful <br /> vacuums<span className="SpanBigger">,</span> shampoo<span className="SpanBigger">,</span>{" "}
              fragrance and air vacuums. <br /> Plus our vending machines offer premium towels and car care products.
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      {/* For Mob */}
      {/*  */}
      <div className="ServicesS1New ServicesS1NewMob" id="SelfServiceMob">
        <div className="ServicesS1NewP1">
          <div className="ServicesS1NewP1V1">Why Choose Wash Your Stuff</div>
          <div className="ServicesS1NewP1V2">More Than Just a Car Wash</div>
        </div>
        {/*  */}
        <div className="ServicesS1NewP2">
          <div className="ServicesS1NewP2V2 TextRight">
            <div className="ServicesS1NewP2V2T1">
              <img src={AboutS2Img} alt="" />
              Self-Service wash
            </div>
            <div className="ServicesS1NewP2V1">
              <img src={ServicesS1NewP2Img1} alt="" className="ServicesS1NewP2V1Img ImgLeft" />
            </div>
            <div className="ServicesS1NewP2V2T2" id="TouchFreeMob">
              Our self-service bays are ideal for those who want to take charge of their cleaning. You can wash your car<span className="SpanBigger">,</span>{" "}
              trucks<span className="SpanBigger">,</span> bikes<span className="SpanBigger">,</span> mats<span className="SpanBigger">,</span> RV’s
              <span className="SpanBigger">,</span> boats and more. And don't forget to use our air blowers for a spotless finish.
            </div>
          </div>
        </div>
        {/*  */}
        <div className="ServicesS1NewP2">
          <div className="ServicesS1NewP2V2">
            <div className="ServicesS1NewP2V2T1">
              <img src={AboutS2Img} alt="" />
              Touch-Free Automatic Wash
            </div>
            <div className="ServicesS1NewP2V1">
              <img src={ServicesS1NewP2Img2} alt="" className="ServicesS1NewP2V1Img" />
            </div>
            <div className="ServicesS1NewP2V2T2" id="PetPaysMob">
              Touch-free car washes provide a safe and gentle way to clean your vehicle. Unlike traditional friction tunnel washes
              <span className="SpanBigger">,</span> we only use specialized chemicals and powerful water pressure to minimize the risk of paint finish damage.
            </div>
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="ServicesS1NewP2">
          <div className="ServicesS1NewP2V2">
            <div className="ServicesS1NewP2V2T1">
              <img src={AboutS2Img} alt="" />
              Spoil Your Furry Friend
            </div>
            <div className="ServicesS1NewP2V1">
              <img src={ServicesS1NewP2Img5} alt="" className="ServicesS1NewP2V1Img" />
            </div>
            <div className="ServicesS1NewP2V2T2" id="PetParkMob">
              And don't forget to let your furry friend play in our complimentary pet playground before or after their bath!
            </div>
          </div>
        </div>
        {/*  */}
        <div className="ServicesS1NewP2">
          <div className="ServicesS1NewP2V2 TextRight">
            <div className="ServicesS1NewP2V2T1">
              <img src={AboutS2Img} alt="" />
              Pamper Your Pets
            </div>
            <div className="ServicesS1NewP2V1">
              <img src={ServicesS1NewP2Img3} alt="" className="ServicesS1NewP2V1Img ImgLeft" />
            </div>
            <div className="ServicesS1NewP2V2T2" id="VacuumsMob">
              Pamper your pet at our pet wash bays, featuring pet-friendly tubs, warm water, soap, conditioner, grooming vacuums, and hair dryers.
            </div>
          </div>
        </div>
        {/*  */}
        <div className="ServicesS1NewP2">
          <div className="ServicesS1NewP2V2">
            <div className="ServicesS1NewP2V2T1">
              <img src={AboutS2Img} alt="" />
              Vacuums & Vending Machines
            </div>
            <div className="ServicesS1NewP2V1">
              <img src={ServicesS1NewP2Img4} alt="" className="ServicesS1NewP2V1Img " />
            </div>
            <div className="ServicesS1NewP2V2T2">
              Get superior car cleaning with our powerful vacuums<span className="SpanBigger">,</span> shampoo<span className="SpanBigger">,</span> fragrance
              and air vacuums. Plus our vending machines offer premium towels br and car care products.
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </>
  );
}

// Services S2
const slides = [
  {
    image: ServicesSlideImg1,
    alt: "srv wash your car",
  },
  {
    image: ServicesSlideImg2,
    alt: "srv pet wash",
  },
  {
    image: ServicesSlideImg3,
    alt: "srv pet park",
  },
  {
    image: ServicesSlideImg4,
    alt: "srv do it your self",
  },
  {
    image: ServicesSlideImg5,
    alt: "srv automatic car wash",
  },
  {
    image: ServicesSlideImg6,
    alt: "srv self service car wash",
  },
  {
    image: ServicesSlideImg7,
    alt: "srv vacuum car wash",
  },
  {
    image: ServicesSlideImg8,
    alt: "srv boat wash",
  },
  {
    image: ServicesSlideImg9,
    alt: "srv car wash",
  },
  {
    image: ServicesSlideImg10,
    alt: "srv toch free wash",
  },
  {
    image: ServicesSlideImg11,
    alt: "Large car wash bay",
  },
];
function ServiceS2() {
  return (
    <>
      <div className="ServicesS2">
        <div className="ServicesS2P1">
          <div className="ServicesS2P1V1">Our Expertise</div>
          <div className="ServicesS2P1V2">A service for Every stuff</div>
          <div className="ServicesS2P1V3">
            From sedans<span className="SpanBigger">,</span> SUVs<span className="SpanBigger">,</span> boats<span className="SpanBigger">,</span> bikes to RVs
            <span className="SpanBigger">,</span>
            we've got you covered.
          </div>
        </div>
        <div className="ServicesS2P2">
          <ServicesSlider slides={slides} />
        </div>
      </div>
    </>
  );
}
// Services S3
function ServiceS3() {
  const isMobile = useIsMobile();

  return (
    <>
      <div className="ServicesS3">
        <div className="ServicesS3P1">
          <div className="ServicesS2P1V1">Our Location</div>
          <h2>
            explore each location <br /> services
          </h2>
        </div>
        {/*  */}
        <div className="ServicesS3P2">
          {/*  */}
          <div className="ServicesS3P2B1">
            <img src={isMobile ? HomeS3FirstMob : HomeS3First} alt="gpl self wash car" className="ServicesS3P2B1Img" />
            <h1 className="ServicesS3P2B1H1">grand prairie</h1>
            <button className="ServicesS3P2B1H2">NEW LOCATION</button>
            <Link to="/GrandPrairie">
              <button className="ServicesS3P2B1Button">
                location services <img src={HomeS3Right} alt="" className="ServicesS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
          <div className="ServicesS3P2B1">
            <img src={isMobile ? HomeS3SecondMob : HomeS3Second} alt="grl self wash car" className="ServicesS3P2B1Img" />
            <h1 className="ServicesS3P2B1H1">Grapevine</h1>
            <Link to="/Grapevine">
              <button className="ServicesS3P2B1Button">
                location services <img src={HomeS3Right} alt="" className="ServicesS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
          <div className="ServicesS3P2B1">
            <img src={isMobile ? HomeS3ThirdMob : HomeS3Third} alt="Klrl self wash car" className="ServicesS3P2B1Img" />
            <h1 className="ServicesS3P2B1H1">Keller</h1>
            <Link to="/Keller">
              <button className="ServicesS3P2B1Button">
                location services <img src={HomeS3Right} alt="" className="ServicesS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}
// Services S4
function ServiceS4() {
  return (
    <>
      <div className="ServicesS4">
        <div className="ServicesS4P1">Join Our Wash Club and enjoy exclusive savings </div>
        <div className="ServicesS4P2">
          Unlock exclusive savings with <br /> Coinless app Wash Club
        </div>
        <div className="ServicesS4P3">
          <Link to="">
            <button>
              Double Your Money Now <img src={AboutRight} alt="" />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
