import React from "react";
import "./SmallCart.css";
import RightMark from "../../../Assets/svg/Branches/RightMark.svg";

export default function SmallCart({ title, subtitle, serviceTitle, price, leftItems, rightItems, imageSrc, imageAlt, height }) {
  return (
    <>
      <div className="SmallCartContainer">
        <div className="SmallCartS1">
          <div className="SmallCartS1P1">{title}</div>
          <div className="SmallCartS1P2">{subtitle}</div>
        </div>
        <div className="SmallCartS2">
          <div className="SmallCartS2P1" style={{ height }}>
            <div className="SmallCartS2P1Head">
              <div className="SmallCartS2P1HeadL1">{serviceTitle}</div>
              <div className="SmallCartS2P1HeadL2">{price}</div>
              <div className="SmallCartS2P1HeadL3Border"></div>
            </div>
            <div className="SmallCartS2P1Items">
              <div className="SmallCartS2P1ItemsL">
                {leftItems.map((item, index) => (
                  <div key={index} className="SmallCItem">
                    <img src={RightMark} alt="" className="SmallCItemImg" />
                    <div className="SmallCItemText">{item}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="SmallCartS2P2">
            <img src={imageSrc} alt={imageAlt} className="SmallCartS2P2Img" />
          </div>
        </div>
      </div>

      {/*  */}
      {/* for Mob */}
    </>
  );
}
