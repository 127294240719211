import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./HeaderHome.css";
import HeaderHomeLogo from "../../Assets/svg/Header/Logo.svg";
import HeaderHomeLogoScrolled from "../../Assets/svg/Header/LogoScroll.svg";
import ButtonIcon from "../../Assets/svg/Header/Button_Icon.svg";
import WashClub from "../../Assets/svg/Header/WashClub.svg";
import WashClubScrolled from "../../Assets/svg/Header/WashClubWhite.svg";

export default function HeaderHomeWeb() {
  const [isScrolled, setIsScrolled] = useState(false);
  const currentPath = window.location.pathname;

  const isActive = (path) => currentPath === path;

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = window.innerHeight * 0.95;
      if (window.scrollY > scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`HeaderHomeWeb_Absoulte ${isScrolled ? "scrolled" : ""}`}>
        <div className="HeaderHomeWeb">
          <div>
            <Link to="/">
              <img src={isScrolled ? HeaderHomeLogoScrolled : HeaderHomeLogo} alt="" className="HeaderHomeWebImg" />
            </Link>
          </div>
          <div className="HeaderHomeRoute">
            <Link to="" className={`${isActive("/") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              Home
            </Link>
            <Link to="About-us" className={`${isActive("/About-us") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              About Us
            </Link>
            <Link to="Services" className={`${isActive("/Services") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              Services
            </Link>
            <Link to="/faqs" className={`${isActive("/faqs") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              FAQs
            </Link>
            <Link to="/blog" className={`${isActive("/blog") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              Blog
            </Link>
            <Link to="/washclub" className={`${isActive("/washclub") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              <div className="WashClubCont">
                WashClub <img src={isScrolled ? WashClub : WashClubScrolled} alt="" className="WashClubSvg" />
              </div>
            </Link>
            <Link to="/Contact-us" className={`${isActive("/Contact-us") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              Contact us
            </Link>
            <Link to="/Locations">
              <button className="HeaderHomeButton">
                <img src={ButtonIcon} alt="" className="HeaderHomeButtonImg" />
                Our Locations
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
