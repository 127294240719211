import React from "react";
import "./Article.css";
import Article from "../../Assets/hooks/Reuse_Component/ArticleComponent/ArticleComponent";
import { BlogData1 } from "../Blog/Blog";
import { BlogData2 } from "../Blog/Blog";
import { BlogData3 } from "../Blog/Blog";
import "./Article.css";
import SharePost from "../../Component/SharePost's/SharePost";
const QuestionsData1 = [
  {
    Question: <>Introduction</>,
    Answer: (
      <>
        The benefits of regularly washing your vehicle go far beyond cosmetic appeal. Having a clean car is part of your vehicle’s routine maintenance. When you
        invest in regular carwashes<span className="SpanBigger">,</span> you can:
      </>
    ),
  },
  {
    Question: <>1- Get your money’s worth.</>,
    Answer: (
      <>
        Keeping a clean car prevents accumulation of dust and dirt from damaging your finished paint. Some dusts<span className="SpanBigger">,</span> pollens
        <span className="SpanBigger">,</span>
        bird droppings<span className="SpanBigger">,</span> rain<span className="SpanBigger">,</span> and road grime have chemicals and salts that can damage a
        paint’s finish<span className="SpanBigger">,</span> particularly with prolonged contact. In addition<span className="SpanBigger">,</span> most car
        washes not only remove these chemicals<span className="SpanBigger">,</span> they also offer optional protective coatings to protect your vehicle against
        the elements. For example<span className="SpanBigger">,</span> a clear-coat protectant helps protect the vehicle’s paint from a variety of dirts and
        chemicals<span className="SpanBigger">,</span> protects against the damaging effects of sunlight and ultraviolet (UV) rays
        <span className="SpanBigger">,</span> moisture<span className="SpanBigger">,</span> and oxidation that can all make paint begin to fade. Similar and
        improved coatings like Carnauba Wax and Ceramic based coatings will further enhance the protection to your finished paint. They will also help manage
        small scratches.
      </>
    ),
  },
  {
    Question: <>2- Preserve your vehicle’s value and prolong its lifespan.</>,
    Answer: (
      <>
        If you plan to sell your vehicle in the future or keep it until the wheels fall off<span className="SpanBigger">,</span> it pays to think ahead. Washing
        your vehicle regularly will help protect your investment. While a variety of factors can impact a vehicle’s longevity
        <span className="SpanBigger">,</span> maintaining a clean car is amongst the easiest owner maintenance tasks that does not involve a mechanic or body
        shop. Please do not overlook the importance of regular car washes. The clear coat protectants<span className="SpanBigger">,</span> waxes
        <span className="SpanBigger">,</span> and ceramics will further protect your vehicles exterior.
      </>
    ),
  },
  {
    Question: <>3- Protect you and your passengers’ safety.</>,
    Answer: (
      <>
        Having a clean windshield<span className="SpanBigger">,</span> windows<span className="SpanBigger">,</span> mirrors<span className="SpanBigger">,</span>{" "}
        headlights<span className="SpanBigger">,</span> signal lights<span className="SpanBigger">,</span> and tires is critical to your vehicles safety on the
        road. Clean headlights will lead to maximum illumination. Washing your vehicle regularly is part of your maintenance to keep you and your passengers
        safe. Also
        <span className="SpanBigger">,</span> make sure your tires and fenders are clear of dirt and debris to insure your vehicle’s most optimal breaking
        ability.
      </>
    ),
  },
  {
    Question: <>4- Enjoy cleaning your car.</>,
    Answer: (
      <>
        Many of us actually enjoy cleaning and detailing our own vehicles. Even better is how driving a clean vehicle make one feel! It’s surprising how a clean
        vehicle can affect your mood and stress level. We call that “new car” feeling!
      </>
    ),
  },
  {
    Question: <>Conclusion</>,
    Answer: (
      <>
        If you are in Grapevine or Keller<span className="SpanBigger">,</span> and if you are looking for a good place for a self service or touch-free carwash
        <span className="SpanBigger">,</span>
        check out Wash Your Stuff Grapevine, Keller or Grand Prairie
      </>
    ),
  },
  {
    Question: <></>,
    Answer: (
      <>
        <SharePost />
      </>
    ),
  },
];

const RelatedArticlesData = [...BlogData1, ...BlogData2];

export default function ArticlePage3() {
  return (
    <div className="ArticlePage">
      <Article ArticleData={BlogData3} QuestionsData={QuestionsData1} RelatedArticlesData={RelatedArticlesData} />
    </div>
  );
}
