import React from "react";
import "./BigCart.css";
import RightMark from "../../../Assets/svg/Branches/RightMark.svg";

export default function BigCart({ title, subtitle, serviceTitle, price, leftItems, rightItems, imageSrc, imageAlt }) {
  return (
    <div className="BigCartContainer">
      <div className="BigCartS1">
        <div className="BigCartS1P1">{title}</div>
        <div className="BigCartS1P2">{subtitle}</div>
      </div>
      <div className="BigCartS2">
        <div className="BigCartS2P1">
          <div className="BigCartS2P1Head">
            <div className="BigCartS2P1HeadL1">{serviceTitle}</div>
            <div className="BigCartS2P1HeadL2">{price}</div>
            <div className="BigCartS2P1HeadL3Border"></div>
          </div>
          <div className="BigCartS2P1Items">
            <div className="BigCartS2P1ItemsL">
              {leftItems.map((item, index) => (
                <div key={index} className="BigCItem">
                  <img src={RightMark} alt="" className="BigCItemImg" />
                  <div className="BigCItemText">{item}</div>
                </div>
              ))}
            </div>
            <div className="BigCartS2P1ItemsR">
              {rightItems.map((item, index) => (
                <div key={index} className="BigCItem">
                  <img src={RightMark} alt="" className="BigCItemImg" />
                  <div className="BigCItemText">{item}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="BigCartS2P2">
          <img src={imageSrc} alt={imageAlt} className="BigCartS2P2Img" />
        </div>
      </div>
    </div>
  );
}
