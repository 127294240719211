import React from "react";
import "./SharePost.css";
import FacebookIcon from "../../Assets/svg/Footer/FaceBook_Logo.svg";
import TwitterIcon from "../../Assets/svg/Blog/XIcon.svg";
export default function SharePost() {
  const shareOnFacebook = () => {
    const postUrl = window.location.href;
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postUrl)}`;
    window.open(facebookShareUrl, "_blank");
  };

  const shareOnTwitter = () => {
    const postUrl = window.location.href;
    const text = "Check out this article!";
    const twitterShareUrl = `https://x.com/intent/tweet?url=${encodeURIComponent(postUrl)}&text=${encodeURIComponent(text)}`;
    window.open(twitterShareUrl, "_blank");
  };
  return (
    <>
      <div className="Article_Share_Section">
        <span>Share this post:</span>
        <img src={FacebookIcon} alt="Share on Facebook" className="Article_Share_Icon" onClick={shareOnFacebook} />
        <img src={TwitterIcon} alt="Share on Twitter" className="Article_Share_Icon" onClick={shareOnTwitter} />
      </div>
    </>
  );
}
