import React, { useState, useEffect, useRef } from "react";
import "./Slider.css";
import star from "../../../svg/FeedBack/StarGoldFB.svg";
import HomeS6IconLeft from "../../../svg/Home/HomeS6IconLeft.svg";
import HomeS6IconRight from "../../../svg/Home/HomeS6IconRight.svg";

export default function Slider({ slides }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    slides.forEach((slide) => {
      const img = new Image();
      img.src = slide.image;
    });
  }, [slides]);

  const handleNextSlide = () => {
    setIsImageLoaded(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
    }, 300);
  };

  const handlePrevSlide = () => {
    setIsImageLoaded(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
    }, 300);
  };

  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      handleNextSlide();
    }, 6000);

    return () => clearInterval(timerRef.current);
  }, [currentIndex]);

  const renderStars = (starsCount) => {
    return Array.from({ length: starsCount }, (_, index) => <img key={index} src={star} alt="star" className="star_image" />);
  };

  return (
    <>
      <div className="slider_container">
        {/* Left Section: Text */}
        <div className="Home_Slider_Left">
          <div className="HomeSliderLP1">{slides[currentIndex].paragraph}</div>
          <div className="HomeSliderLP2">
            <div className="HomeSliderLP2V1">
              {slides[currentIndex].clientName}
              <div>{renderStars(slides[currentIndex].stars)}</div>
            </div>
            <div className="HomeSliderLP2V2">{slides[currentIndex].branchName}</div>
          </div>
        </div>

        {/* Right Section: Image */}
        <div className="slider_image">
          {!isImageLoaded && <div className="image-placeholder"></div>}
          <img
            src={slides[currentIndex].image}
            alt={slides[currentIndex].title}
            className={`HomeSliderImageS6 ${isImageLoaded ? "loaded" : ""}`}
            onLoad={() => setIsImageLoaded(true)}
          />
          <div className="slider_button">
            <div
              className="prev"
              onClick={() => {
                handlePrevSlide();
              }}
            >
              <img src={HomeS6IconLeft} alt="Previous" className="HomeS6Icon" />
            </div>
            <div
              className="next"
              onClick={() => {
                handleNextSlide();
              }}
            >
              <img src={HomeS6IconRight} alt="Next" className="HomeS6Icon" />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="slider_container slider_container_mob">
        <div className="slider_image">
          <img
            src={slides[currentIndex].image}
            alt={slides[currentIndex].title}
            className={`HomeSliderImageS6 ${isImageLoaded ? "loaded" : ""}`}
            onLoad={() => setIsImageLoaded(true)}
          />
          <div className="slider_button">
            <div
              className="prev"
              onClick={() => {
                handlePrevSlide();
              }}
            >
              <img src={HomeS6IconLeft} alt="Previous" className="HomeS6Icon" />
            </div>
            <div
              className="next"
              onClick={() => {
                handleNextSlide();
              }}
            >
              <img src={HomeS6IconRight} alt="Next" className="HomeS6Icon" />
            </div>
          </div>
        </div>
        <div className="Home_Slider_Left">
          <div className="HomeSliderLP1">{slides[currentIndex].paragraph}</div>
          <div className="HomeSliderLP2">
            <div className="HomeSliderLP2V1">
              {slides[currentIndex].clientName}
              <div>{renderStars(slides[currentIndex].stars)}</div>
            </div>
            <div className="HomeSliderLP2V2">{slides[currentIndex].branchName}</div>
          </div>
        </div>
      </div>
    </>
  );
}
