import React, { useState, useEffect } from "react";
import "./BigCartMob.css";
import RightMark from "../../../Assets/svg/Branches/RightMark.svg";
import OpenIcon from "../../../Assets/svg/Branches/OpenIcon.svg";
import CloseIcon from "../../../Assets/svg/Branches/CloseIcon.svg";

export default function BigCartMob({ title, subtitle, serviceTitle, price, leftItems, rightItems, imageSrcMob, imageAlt }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [computedHeight, setComputedHeight] = useState("55vh");

  useEffect(() => {
    const updateHeight = () => {
      if (window.innerWidth <= 575 && !isExpanded) {
        setComputedHeight("auto");
      } else {
        setComputedHeight(isExpanded ? "auto" : "55vh");
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [isExpanded]);

  return (
    <div className="BigCartContainerMob">
      <div className="BigCartMobS1">
        <div className="BigCartMobTitle">{title}</div>
        <div className="BigCartMobSubtitle">{subtitle}</div>
      </div>
      <div className="BigCartMobS2">
        <div className="BigCartMobS2P1" style={{ height: computedHeight }}>
          <div className="BigCartMobS2P1Head">
            <div className="BigCartMobS2P1HeadL1">{serviceTitle}</div>
            <div className="BigCartMobS2P1HeadL2">{price}</div>
            <div className="BigCartMobS2P1HeadL3Border"></div>
          </div>
          <div className="BigCartMobS2P1Items">
            {/* Left Items */}
            {leftItems.slice(0, isExpanded ? leftItems.length : 3).map((item, index) => (
              <div key={index} className="BigCItemMob">
                <img src={RightMark} alt="" className="BigCItemMobImg" />
                <div className="BigCItemMobText">{item}</div>
              </div>
            ))}

            {/* Right Items */}
            {isExpanded &&
              rightItems.map((item, index) => (
                <div key={index} className="BigCItemMob">
                  <img src={RightMark} alt="" className="BigCItemMobImg" />
                  <div className="BigCItemMobText">{item}</div>
                </div>
              ))}

            {/* View All / View Less Button */}
            {!isExpanded ? (
              <div className="ViewAllCont">
                <div className="ShadowBCMob"></div>
                <button className="ViewAllButtonMob" onClick={() => setIsExpanded(true)}>
                  View All <img src={OpenIcon} alt="" />
                </button>
              </div>
            ) : (
              <button className="CloseButtonMob" onClick={() => setIsExpanded(false)}>
                View Less <img src={CloseIcon} alt="" />
              </button>
            )}
          </div>
        </div>
        <div className="BigCartMobS2P2">
          <img src={imageSrcMob} alt={imageAlt} className="BigCartMobS2P2Img" />
        </div>
      </div>
    </div>
  );
}
