import React, { useState } from "react";
import "./Faqs.css";
import RightIcon from "../../../svg/Home/RightIcon.svg";
import UpIcon from "../../../svg/Home/UpIcon.svg";

export default function Faqs({ faqData }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            {faq.question}
            <img src={activeIndex === index ? UpIcon : RightIcon} alt={activeIndex === index ? "Collapse" : "Expand"} className="faq-icon" />
          </div>
          {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
        </div>
      ))}
    </div>
  );
}
