import React, { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import HomeS3Right from "../../Assets/svg/Home/HomeS3Right.svg";
import ContactUsS2Svg from "../../Assets/svg/ContactUs/ContactUsS2.svg";
import "./ContactUs.css";
import { Link } from "react-router-dom";
export default function FormWeb() {
  const formRef = useRef();
  const [captcha, setCaptcha] = useState("");
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptcha(`${num1} + ${num2}`);
  };

  const validateInputs = (formData) => {
    const name = formData.get("user_name").trim();
    const email = formData.get("user_email").trim();
    const message = formData.get("user_feedback").trim();

    if (name.length < 3) {
      alert("Name is required.");
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return false;
    }

    if (message.length < 3) {
      alert("Message is required.");
      return false;
    }

    return true;
  };

  const sendEmailAfterVerification = (e) => {
    e.preventDefault();

    const form = formRef.current;
    if (!form) {
      console.error("Form not found!");
      return;
    }

    const formData = new FormData(form);

    if (!validateInputs(formData)) return;

    setIsCaptchaOpen(true);
  };

  const handleCheckboxChange = (event) => {
    setIsSubscribed(event.target.checked);
  };

  const verifyCaptchaAndSendEmail = async () => {
    const captchaValues = captcha.split(" + ").map(Number);
    const correctCaptchaAnswer = captchaValues[0] + captchaValues[1];

    if (parseInt(userCaptchaInput) !== correctCaptchaAnswer) {
      alert("Captcha verification failed. Please try again.");
      return;
    }

    setIsLoading(true);
    try {
      const form = formRef.current;
      const formData = new FormData(form);

      const userSubscribed = isSubscribed ? "Yes" : "No";
      formData.append("user_subscribe", userSubscribed);
      formData.append("subscription_message", isSubscribed ? "User has subscribed to the newsletter." : "User opted out of the newsletter.");

      const response = await emailjs.sendForm("service_2c4av3v", "template_46kqj38", form, "ZriX-Iwn-CNeDoOPJ");

      if (response.status === 200) {
        setIsSuccess(true);
        generateCaptcha(); // Generate new CAPTCHA
      } else {
        throw new Error("Failed to send email.");
      }

      setCaptcha("");
      setUserCaptchaInput("");
      form.reset();
      setIsSubscribed(false);
    } catch (error) {
      console.error("EmailJS Error: ", error);
      alert("Failed to send email. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const enforceWordLimit = (event, maxWords) => {
    const textarea = event.target;
    const words = textarea.value.trim().split(/\s+/); // Split by whitespace
    if (words.length > maxWords) {
      textarea.value = words.slice(0, maxWords).join(" ");
      alert(`You can only enter up to ${maxWords} words.`);
    }
  };

  return (
    <>
      <form ref={formRef} onSubmit={sendEmailAfterVerification}>
        <div className="ContactUsBig">
          <div className="ContactUsS1P1">contact us</div>
          {/*  */}
          <div className="ContactUsS">
            <div className="ContactUsS1">
              <div className="ContactUsS1P2">
                <div className="ContactUsS1P2V">
                  <div className="ContactUsS1P2VFN">(*Max 1000 Character)</div>
                  <label className="input">
                    <input className="input__field input_1" type="text" placeholder="Name *" name="user_name" required />
                  </label>

                  <label className="input">
                    <input className="input__field input_1" type="text" placeholder="Email *" name="user_email" required />
                  </label>

                  {/* Star Rating Dropdown with react-select */}

                  <label className="input">
                    <textarea
                      className="input__field BigContactUs"
                      placeholder="Message"
                      name="user_feedback"
                      maxLength={1000}
                      onInput={(e) => enforceWordLimit(e, 1000)}
                      required
                    />
                  </label>
                </div>
              </div>

              {/* Captcha Pop-up */}
              {isCaptchaOpen && (
                <div className="captchaModal">
                  <div className="captchaModalContent">
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : isSuccess ? (
                      <>
                        <p>
                          Thanks<span className="SpanBigger">,</span> your email has been sent<span className="SpanBigger">,</span> and one of our team members
                          will contact you soon.
                        </p>
                        <button onClick={() => setIsCaptchaOpen(false)}>Close</button>
                      </>
                    ) : (
                      <>
                        <h3>Verify you are human</h3>
                        <p>What is {captcha}?</p>
                        <input
                          type="text"
                          placeholder="Enter the answer"
                          value={userCaptchaInput}
                          onChange={(e) => setUserCaptchaInput(e.target.value)}
                          required
                        />
                        <button onClick={verifyCaptchaAndSendEmail}>Verify and Send</button>
                        <button
                          onClick={() => {
                            setIsCaptchaOpen(false); // Close modal
                            setUserCaptchaInput(""); // Clear input
                            generateCaptcha(); // Generate a fresh CAPTCHA
                          }}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/*  */}
            <div className="ContactUsS2">
              <div className="ContactUsS2P1">Wash Your Stuff </div>
              <div className="ContactUsS2P2">
                {/*  */}
                <Link to="/Locations">
                  <div className="ContactUsS2P2V">
                    <img src={ContactUsS2Svg} alt="" className="ContactUsS2Svg" />
                    <div className="ContactUsS2P2VText">
                      718 Davis St<span className="SpanBigger">,</span> <br />
                      Grand Prairie<span className="SpanBigger">,</span> TX 75050<span className="SpanBigger">.</span>
                    </div>
                  </div>
                </Link>

                {/*  */}
                <Link to="/Locations">
                  <div className="ContactUsS2P2V">
                    <img src={ContactUsS2Svg} alt="" className="ContactUsS2Svg" />
                    <div className="ContactUsS2P2VText">
                      2201 Ira E Woods Ave<span className="SpanBigger">,</span> <br />
                      Grapevine<span className="SpanBigger">,</span> TX 76051<span className="SpanBigger">.</span>
                    </div>
                  </div>
                </Link>

                {/*  */}
                <Link to="/Locations">
                  <div className="ContactUsS2P2V">
                    <img src={ContactUsS2Svg} alt="" className="ContactUsS2Svg" />
                    <div className="ContactUsS2P2VText">
                      412 N Main St<span className="SpanBigger">,</span> <br />
                      Keller<span className="SpanBigger">,</span> TX 76248<span className="SpanBigger">.</span>
                    </div>
                  </div>
                </Link>
                {/*  */}
              </div>
            </div>
          </div>
          {/*  */}
          <div className="ContactSignSend">
            <div className="solutionQuestion">
              <input type="checkbox" id="subscribeCheckbox" className="ContactUsCheckBox" checked={isSubscribed} onChange={handleCheckboxChange} />
              <label htmlFor="subscribeCheckbox">
                Sign up for our email list for updates<span className="SpanBigger">,</span> promotions<span className="SpanBigger">,</span> and more.
              </label>
            </div>

            <input type="hidden" name="user_subscribe" value={isSubscribed ? "Yes" : "No"} />

            <input type="hidden" name="subscription_message" value={isSubscribed ? "User has subscribed to the newsletter." : "Not Avaliable"} />

            <div>
              <button className="ContactButton" type="submit">
                Send Us <img src={HomeS3Right} alt="" className="ContactButtonImg" />
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="ContactusS4">
        <div className="ContactusS4P1">Contact us by text Phone number</div>
        {/*  */}
        <div className="ContactusS4P2">817-380-8460</div>
        {/*  */}
        <div className="ContactusS4P3">
          <a href="sms:817-380-8460">
            <div className="ContactusS4P3B">
              Text us <img src={HomeS3Right} alt="" />{" "}
            </div>
          </a>
        </div>
      </div>
      {/*  */}
    </>
  );
}
