import React from "react";
import "./DisplayBranches.css";
import { Link } from "react-router-dom";
import DisplayBranchesRight from "../../../Assets/svg/Home/HomeS3Right.svg";

export default function DisplayBranches({ BranchName, images, Alt, Url, ButtonTitle }) {
  return (
    <>
      <div className="DisplayS3P2">
        <div className="DisplayS3P2B1">
          <img src={images} alt={Alt} className="DisplayS3P2B1Img" />
          <h1 className="DisplayS3P2B1H1">{BranchName}</h1>
          <Link to={Url}>
            <button className="DisplayS3P2B1Button">
              {ButtonTitle} <img src={DisplayBranchesRight} alt="" className="DisplayS3RightImg" />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
