import React, { useState, useEffect } from "react";
import "./ServicesSlider.css";
import ServiceIconSliderLeft from "../../../svg/Services/ServiceIconSliderLeft.svg";
import ServiceIconSliderRight from "../../../svg/Services/ServiceIconSliderRight.svg";
import ActiveIndicatorImage from "../../../svg/Services/Services_Slider_Icon_Active.svg";
import InactiveIndicatorImage from "../../../svg/Services/Services_Slider_Icon_NonActive.svg";

export default function ServicesSlider({ slides }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Handle the next slide
  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
  };

  // Handle the previous slide
  const handlePrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextSlide();
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="Service_slider_container">
      <div className="Service_slider_image">
        <img src={slides[currentIndex].image} alt={slides[currentIndex].alt} className="Service_SliderImageS2" />

        <div className="Service_slider_button">
          <div className="prev" onClick={handlePrevSlide}>
            <img src={ServiceIconSliderLeft} alt="Previous" className="Service_S2IconLeft" />
          </div>
          <div className="next" onClick={handleNextSlide}>
            <img src={ServiceIconSliderRight} alt="Next" className="Service_S2IconRight" />
          </div>
        </div>

        <div className="Service_slider_indicators">
          {slides.map((_, index) => (
            <div key={index} className="Service_slider_indicator">
              <img
                src={index === currentIndex ? ActiveIndicatorImage : InactiveIndicatorImage}
                alt={`Indicator ${index + 1}`}
                className="Service_slider_indicator_image"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
