import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import "./Footer.css";
import Logo from "../../Assets/svg/Header/LogoScroll.svg";
import FaceBook from "../../Assets/svg/Footer/FaceBook_Logo.svg";
import Instgram from "../../Assets/svg/Footer/Instgram_Logo.svg";
import TikTok from "../../Assets/svg/Footer/TikTok_Logo.svg";
import Akar from "../../Assets/svg/Footer/Akar_Logo.svg";
import HomeS3Right from "../../Assets/svg/Home/HomeS3Right.svg";
import RightIcon from "../../Assets/svg/Home/RightIcon.svg";
export default function FooterWeb() {
  const formRef = useRef();
  const [captcha, setCaptcha] = useState("");
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptcha(`${num1} + ${num2}`);
  };

  const validateInputs = (formData) => {
    const email = formData.get("user_email").trim();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return false;
    }

    return true;
  };

  const sendEmailAfterVerification = (e) => {
    e.preventDefault();

    const form = formRef.current;
    if (!form) {
      console.error("Form not found!");
      return;
    }

    const formData = new FormData(form);

    if (!validateInputs(formData)) return;

    setIsCaptchaOpen(true);
  };

  const verifyCaptchaAndSendEmail = async () => {
    const captchaValues = captcha.split(" + ").map(Number);
    const correctCaptchaAnswer = captchaValues[0] + captchaValues[1];

    if (parseInt(userCaptchaInput) !== correctCaptchaAnswer) {
      alert("Captcha verification failed. Please try again.");
      return;
    }

    setIsLoading(true);
    try {
      const form = formRef.current;
      const formData = new FormData(form);

      const userSubscribed = isSubscribed ? "Yes" : "No";
      formData.append("user_subscribe", userSubscribed);
      formData.append("subscription_message", isSubscribed ? "User has subscribed to the newsletter." : "User opted out of the newsletter.");

      const response = await emailjs.sendForm("service_hp18bid", "template_0l9udqi", form, "Jb9wCQ2g0-HRyXbdc");

      if (response.status === 200) {
        setIsSuccess(true);
        generateCaptcha(); // Generate new CAPTCHA
      } else {
        throw new Error("Failed to send email.");
      }

      setCaptcha("");
      setUserCaptchaInput("");
      form.reset();
      setIsSubscribed(false);
    } catch (error) {
      console.error("EmailJS Error: ", error);
      alert("Failed to send email. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="FooterWebContainer">
        <div className="FooterWebMain">
          <div className="FooterWebMainS1">Mailing list & offers</div>
          <div className="FooterWebMainS2">
            <form ref={formRef} onSubmit={sendEmailAfterVerification} className="FooterWebMainS2">
              <label className="input">
                <input className="Footer_Input" type="text" placeholder="E-Mail *" name="user_email" required />
              </label>
              <button className="Footer_Main_Button" type="submit">
                Subscribe <img src={HomeS3Right} alt="" className="FooterButtonIcon" />
              </button>
            </form>
          </div>
        </div>

        {isCaptchaOpen && (
          <div className="captchaModal">
            <div className="captchaModalContent">
              {isLoading ? (
                <p>Loading...</p>
              ) : isSuccess ? (
                <>
                  <p>
                    Thanks<span className="SpanBigger">,</span> your email has been sent<span className="SpanBigger">,</span> and one of our team members will
                    contact you soon.
                  </p>
                  <button onClick={() => setIsCaptchaOpen(false)}>Close</button>
                </>
              ) : (
                <>
                  <h3>Verify you are human</h3>
                  <p>What is {captcha}?</p>
                  <input type="text" placeholder="Enter the answer" value={userCaptchaInput} onChange={(e) => setUserCaptchaInput(e.target.value)} required />
                  <button onClick={verifyCaptchaAndSendEmail}>Verify and Send</button>
                  <button
                    onClick={() => {
                      setIsCaptchaOpen(false); // Close modal
                      setUserCaptchaInput(""); // Clear input
                      generateCaptcha(); // Generate a fresh CAPTCHA
                    }}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        {/*  */}
        <div className="FooterWebS2"></div>
        {/*  */}
        <div className="FooterWebS1">
          {/*  */}
          <div className="FooterWebS1V1">
            <h3>Pages</h3>
            <Link to="/">Home</Link>
            <Link to="About-us">About Us</Link>
            <Link to="/Services">Services</Link>
            <Link to="/FAQs">FAQs</Link>
            <Link to="/Blog">Blog</Link>
            <Link to="/Contact-us">Contact us</Link>
          </div>
          {/*  */}
          <div className="FooterWebS1V1">
            <h3>Contact Us</h3>
            <div className="PhoneNumberTD">
              Phone
              <a href="tel:+18173808460" className="">
                <br /> (817) 380-8460
              </a>
            </div>
            <a href="mailto:support@washyourstuff.com">
              Email <br /> support@washyourstuff.com
            </a>
          </div>
          {/*  */}
          <div className="FooterWebS1V1">
            <div className="FooterWebS1V1">
              <h3>Locations</h3>
              <Link to="/GrandPrairie">Grand Prairie Location</Link>
              <Link to="/Grapevine">Grapevine Location</Link>
              <Link to="/Keller">Keller Location</Link>
            </div>
          </div>
          {/*  */}
          <div className="FooterWebS1V1">
            <div className="FooterWebS1V2">
              <h3>
                Subscribe Now <br /> wash club
              </h3>
              <div className="FooterWebS1V2Button">
                <Link to="/WashClub" className="FooterWebS1V2Button">
                  <button className="Footer_Main_ButtonTall">
                    Learn More <img src={HomeS3Right} alt="" className="HomeS7P1V3Icon" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/*  */}
          {/*  */}
        </div>
        {/*  */}
        <div className="FooterWebS2"></div>
        {/*  */}
        <div className="FooterWebS3">
          {/*  */}
          <div>
            <img src={Logo} alt="" className="FooterWebS3V1Img" />
          </div>
          {/*  */}
          <div className="FooterWebS3V2">Copyright © 2024 Wash Your Stuff - All Rights Reserved.</div>
          {/*  */}
          <div className="Footer_Soical">
            <a href="https://www.tiktok.com/@washyourstuff?_t=8qwtvVHoy2y&_r=1" target="blank">
              <img src={TikTok} alt="" className="Footer_Soical_Img" />
            </a>
            <a href="https://www.facebook.com/washyourstuffkeller?mibextid=ZbWKwL" target="blank">
              <img src={FaceBook} alt="" className="Footer_Soical_Img" />
            </a>
            <a href="https://www.instagram.com/washyourstuff?igsh=MTBoZHVuYndtaDF2bQ==" target="blank">
              <img src={Instgram} alt="" className="Footer_Soical_Img" />
            </a>
            {/* <a href="/" target="blank">
              <img src={Akar} alt="" className="Footer_Soical_Img" />
            </a> */}
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}
