import React from "react";

import "./ContactUs.css";
import FormMobile from "./FormMobile";
import FormWeb from "./FormWeb";
export default function ContactUsContent() {
  return (
    <>
      <div className="ContactUsMain">
        <FormMobile />
        <FormWeb />
      </div>
    </>
  );
}
