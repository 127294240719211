import React, { useState, useRef } from "react";
import "./LocationsNew.css";
import LocationMapAll from "../../Assets/svg/Location/Location_Map_All3.svg";
import LocationMain from "../../Assets/svg/Location/Location_Main.svg";
import LocationAlt1 from "../../Assets/svg/Location/Location_Map_GrandPrairie2.svg";
import LocationAlt2 from "../../Assets/svg/Location/Location_Map_Grapviene2.svg";
import LocationAlt3 from "../../Assets/svg/Location/Location_Map_Keller2.svg";
import ButtonIcon from "../../Assets/svg/Header/Button_Icon.svg";
import HomeS3First from "../../Assets/images/Home/HomeS3First.jpg";
import HomeS3FirstMob from "../../Assets/images/Home/HomeS3FirstMob.jpg";
import HomeS3Second from "../../Assets/images/Home/HomeS3Second.jpg";
import HomeS3SecondMob from "../../Assets/images/Home/HomeS3SecondMob.jpg";
import HomeS3Third from "../../Assets/images/Home/HomeS3Third.jpg";
import HomeS3ThirdMob from "../../Assets/images/Home/HomeS3ThirdMob.jpg";
import HomeS3Right from "../../Assets/svg/Home/HomeS3Right.svg";
import AboutRight from "../../Assets/svg/Home/HomeS3Right.svg";
import { Link } from "react-router-dom";
import LocationMobOpen from "../../Assets/svg/Location/LocationMobOpen.svg";
import LocationMobClose from "../../Assets/svg/Location/LocationMobClose.svg";
import LocationMobGrapviene from "../../Assets/svg/Location/LocationMob_Map_Grapviene.svg";
import LocationMobGrandPrairie from "../../Assets/svg/Location/LocationMob_Map_GrandPrairie.svg";
import LocationMobKeller from "../../Assets/svg/Location/LocationMob_Map_Keller.svg";
import GetDircetionMob from "../../Assets/svg/Header/HeaderHomeMobIcon.svg";
import useIsMobile from "../../Assets/hooks/Reuse_Component/IsMobile/IsMobile";

export default function LocationsNew() {
  const [selectedImage, setSelectedImage] = useState({
    img: LocationMapAll,
    url: "",
  });

  const handleBoxClick = (img, url) => {
    setSelectedImage({ img, url });
  };

  return (
    <div className="LocationContainer">
      <LocationS1 />
      <LocationS2 handleBoxClick={handleBoxClick} selectedImage={selectedImage} />
      <LocationS3 />
      <LocationS4 />
    </div>
  );
}
function LocationS1() {
  return (
    <div className="LocationS1">
      <div className="LocationS1P1">
        <div className="LocationS1P1L1">Explore our Locations</div>
        <div className="LocationS1P1L2">
          Your Wash<span className="SpanBiggerMore">,</span> Your Way<span className="SpanBiggerMore">,</span>
          <br />
          Anywhere in Texas
        </div>
      </div>
      <div className="LocationS1P2">
        <div className="LocationS1P2L1">Working Hours</div>
        <div className="LocationS1P2L2">
          OPEN 24/7<span className="SpanBiggerMore">,</span> 7 DAYS A WEEK<span className="SpanBiggerMore">,</span> 365 DAYS A YEAR!
        </div>
      </div>
    </div>
  );
}

function LocationS2({ handleBoxClick, selectedImage }) {
  const [selectedMobItem, setSelectedMobItem] = useState(null);

  const handleMobClick = (itemId) => {
    setSelectedMobItem(selectedMobItem === itemId ? null : itemId);
  };
  const imageBoxRef = useRef(null);

  return (
    <>
      {/* For New One */}
      <div className="LocationS2New">
        <div className="LocationS2NewBoxes">
          {/* Location Boxes */}
          <div className="LocationBoxNew" onClick={() => handleBoxClick(LocationAlt1, "https://maps.app.goo.gl/vjf7c48hCveki6yn6")}>
            <div className="LocationBoxL1">
              <img src={LocationMain} alt="Main location Grand Prairie" className="LocationBox1L1New" />
              <div className="LocationBox1L2">NEW LOCATION</div>
            </div>
            <div className="LocationBoxL2">Grand Prairie</div>
            <div className="LocationBoxL3">718 Davis St, Grand Prairie, TX 75050, (817) 380-8460</div>
            <div className="LocationBoxL4">
              <a href="https://maps.app.goo.gl/vjf7c48hCveki6yn6" target="_blank" rel="noopener noreferrer">
                <button className="LocationBoxL4Button">
                  <img src={ButtonIcon} alt="Get directions to Grand Prairie" className="LocationBoxL4ButtonImg" />
                  Get Direction
                </button>
              </a>
            </div>
          </div>

          {/* Additional Location Boxes */}
          <div className="LocationBoxNew" onClick={() => handleBoxClick(LocationAlt2, "https://maps.app.goo.gl/mYZQ5Pg3quWJ6kqa6")}>
            <div className="LocationBoxL1">
              <img src={LocationMain} alt="Main location Grapevine" className="LocationBox1L1New" />
            </div>
            <div className="LocationBoxL2">Grapevine Address</div>
            <div className="LocationBoxL3">
              2201 Ira E Woods Ave, Grapevine, TX 76051, <br /> (817) 380-8460
            </div>
            <div className="LocationBoxL4">
              <a href="https://maps.app.goo.gl/mYZQ5Pg3quWJ6kqa6" target="_blank" rel="noopener noreferrer">
                <button className="LocationBoxL4Button">
                  <img src={ButtonIcon} alt="Get directions to Grapevine" className="LocationBoxL4ButtonImg" />
                  Get Direction
                </button>
              </a>
            </div>
          </div>

          <div className="LocationBoxNew" onClick={() => handleBoxClick(LocationAlt3, "https://maps.app.goo.gl/yV1pZLoBNX6MChjw9")}>
            <div className="LocationBoxL1">
              <img src={LocationMain} alt="Main location Keller" className="LocationBox1L1New" />
            </div>
            <div className="LocationBoxL2">Keller Address</div>
            <div className="LocationBoxL3">
              412 N Main St, Keller, TX 76248, <br /> (817) 380-8460
            </div>
            <div className="LocationBoxL4">
              <a href="https://maps.app.goo.gl/yV1pZLoBNX6MChjw9" target="_blank" rel="noopener noreferrer">
                <button className="LocationBoxL4Button">
                  <img src={ButtonIcon} alt="Get directions to Keller" className="LocationBoxL4ButtonImg" />
                  Get Direction
                </button>
              </a>
            </div>
          </div>
        </div>
        <div>
          {/* Main Image Display */}
          <div className="ImageBox">
            <a href={selectedImage.url} target="_blank" rel="noopener noreferrer">
              <img
                src={selectedImage.img}
                alt={`Selected location map for ${
                  selectedImage.img === LocationAlt1
                    ? "Grand Prairie"
                    : selectedImage.img === LocationAlt2
                    ? "Grapevine"
                    : selectedImage.img === LocationAlt3
                    ? "Keller"
                    : "All Locations"
                }`}
                className={`LocationImgAll ${
                  selectedImage.img === LocationAlt1 ? "" : selectedImage.img === LocationAlt2 ? "" : selectedImage.img === LocationAlt3 ? "" : ""
                }`}
              />
            </a>
          </div>
        </div>
      </div>
      {/* for Mob */}
      <div className="LocationS2Mob">
        <div className="LocationS2MobHead">Select Location</div>
        {/* Grand Prairie */}
        <div className="LocationBoxMob" onClick={() => handleMobClick(1)}>
          <div className="LocationBoxL1Mob">
            <div className="LocationBoxL2Mob">Grand Prairie</div>
            <div className="LocationBoxL3Mob">NEW LOCATION</div>
            <div className="LocationBoxL4Mob">
              <img src={selectedMobItem === 1 ? LocationMobClose : LocationMobOpen} alt="" className="LocationMobOpen" />
            </div>
          </div>
          {selectedMobItem === 1 && (
            <div className="LocationCardMob">
              <div className="LocationCardMobImgCont">
                <a href="https://maps.app.goo.gl/vjf7c48hCveki6yn6" target="blank">
                  <img src={LocationMobGrandPrairie} alt="" className="LocationCardMobImg" />
                </a>
              </div>
              <div className="LocationCardMobSection">
                <div className="LocationBoxTCart">
                  718 Davis St<span className="SpanBigger">,</span> Grand Prairie<span className="SpanBigger">,</span> TX 75050
                  <span className="SpanBigger">,</span> (817) 380-8460
                </div>
                <a href="https://maps.app.goo.gl/vjf7c48hCveki6yn6" target="_blank" rel="noopener noreferrer">
                  <img src={GetDircetionMob} alt="Get directions to Grapevine" className="LocationBoxL4ButtonImg" />
                </a>
              </div>
            </div>
          )}
        </div>
        {/* Grapevine */}
        <div className="LocationBoxMob" onClick={() => handleMobClick(2)}>
          <div className="LocationBoxL1Mob">
            <div className="LocationBoxL2Mob">Grapevine</div>
            <div className="LocationBoxL4Mob">
              <img src={selectedMobItem === 2 ? LocationMobClose : LocationMobOpen} alt="" className="LocationMobOpen" />
            </div>
          </div>
          {selectedMobItem === 2 && (
            <div className="LocationCardMob">
              <div className="LocationCardMobImgCont">
                <a href="https://maps.app.goo.gl/mYZQ5Pg3quWJ6kqa6" target="blank">
                  <img src={LocationMobGrapviene} alt="" className="LocationCardMobImg" />
                </a>
              </div>
              <div className="LocationCardMobSection">
                <div className="LocationBoxTCart">
                  2201 Ira E Woods Ave<span className="SpanBigger">,</span> Grapevine<span className="SpanBigger">,</span> TX 76051
                  <span className="SpanBigger">,</span> (817) 380-8460
                </div>
                <a href="https://maps.app.goo.gl/mYZQ5Pg3quWJ6kqa6" target="_blank" rel="noopener noreferrer">
                  <img src={GetDircetionMob} alt="Get directions to Grapevine" className="LocationBoxL4ButtonImg" />
                </a>
              </div>
            </div>
          )}
        </div>
        {/* Keller */}
        <div className="LocationBoxMob" onClick={() => handleMobClick(3)}>
          <div className="LocationBoxL1Mob">
            <div className="LocationBoxL2Mob">Keller</div>
            <div className="LocationBoxL4Mob">
              <img src={selectedMobItem === 3 ? LocationMobClose : LocationMobOpen} alt="" className="LocationMobOpen" />
            </div>
          </div>
          {selectedMobItem === 3 && (
            <div className="LocationCardMob">
              <div className="LocationCardMobImgCont">
                <a href="https://maps.app.goo.gl/yV1pZLoBNX6MChjw9" target="blank">
                  <img src={LocationMobKeller} alt="" className="LocationCardMobImg" />
                </a>
              </div>
              <div className="LocationCardMobSection">
                <div className="LocationBoxTCart">
                  412 N Main St<span className="SpanBigger">,</span> Keller<span className="SpanBigger">,</span> TX 76248<span className="SpanBigger">,</span>{" "}
                  (817) 380-8460
                </div>
                <a href="https://maps.app.goo.gl/yV1pZLoBNX6MChjw9" target="_blank" rel="noopener noreferrer">
                  <img src={GetDircetionMob} alt="Get directions to Grapevine" className="LocationBoxL4ButtonImg" />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function LocationS3() {
  const IsMobile = useIsMobile();
  return (
    <>
      <div className="HomeS3">
        <div className="HomeS3P1">
          <h1>Our Location</h1>
          <h2>
            Find Us Near You <br /> we are open 24/7
          </h2>
          <p>
            Experience an Extraordinary wash! <br /> Locate your Nearest Location and enjoy the Difference.
          </p>
        </div>
        {/*  */}
        <div className="HomeS3P2">
          {/*  */}
          <div className="HomeS3P2B1">
            <img src={IsMobile ? HomeS3FirstMob : HomeS3First} alt="gpl self wash car" className="HomeS3P2B1Img" />
            <h1 className="HomeS3P2B1H1">grand prairie</h1>
            <button className="HomeS3P2B1H2">NEW LOCATION</button>
            <Link to="/GrandPrairie">
              <button className="HomeS3P2B1Button">
                location services <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
          <div className="HomeS3P2B1">
            <img src={IsMobile ? HomeS3SecondMob : HomeS3Second} alt="grl self wash car" className="HomeS3P2B1Img" />
            <h1 className="HomeS3P2B1H1">Grapevine</h1>
            <Link to="/Grapevine">
              <button className="HomeS3P2B1Button">
                location services <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
          <div className="HomeS3P2B1">
            <img src={IsMobile ? HomeS3ThirdMob : HomeS3Third} alt="Klrl self wash car" className="HomeS3P2B1Img" />
            <h1 className="HomeS3P2B1H1">Keller</h1>
            <Link to="/Keller">
              <button className="HomeS3P2B1Button">
                location services <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}
function LocationS4() {
  return (
    <>
      <div className="AboutS5">
        <div className="AboutS5P1">Join Our Wash Club and enjoy exclusive savings </div>
        <div className="AboutS5P2">
          Unlock exclusive savings with <br /> Coinless app Wash Club
        </div>
        <div className="AboutS5P3">
          <Link to="/WashClub">
            <button>
              Double Your Money Now <img src={AboutRight} alt="" />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
