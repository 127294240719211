import React from "react";
import "./Article.css";
import Article from "../../Assets/hooks/Reuse_Component/ArticleComponent/ArticleComponent";
import { BlogData1 } from "../Blog/Blog";
import { BlogData2 } from "../Blog/Blog";
import { BlogData3 } from "../Blog/Blog";
import ArticleOneImg1 from "../../Assets/images/Blog/Article-One-Img1.jpg";
import ArticleOneImg2 from "../../Assets/images/Blog/Article-One-Img2.jpg";
import SharePost from "../../Component/SharePost's/SharePost";

const QuestionsData1 = [
  {
    Question: <></>,
    Answer: (
      <>
        If your goal is to clean your car with the least amount of damage to your vehicle keep reading… If your goal is a clean car at any cost then you can
        <span> </span>
        <span className="ArtcilePageUnderLine">not risk micro scratches or scuff marks to a vehicle finish during a wash!</span> Hence, we only believe in Self
        Service<span className="SpanBigger">,</span> Touch-Free<span className="SpanBigger">,</span> or High-End Detailing/Hand-Washing. We offer 2 of these 3
        service lines. We are fortunate to have a neighboring carwash that offers the 3rd option for our customers. We will break up this conversation into 2
        sub-topics depending on the type of vehicle owner you are:
      </>
    ),
  },
  //
  {
    Question: (
      <>
        1. I rarely wash my ride<span className="SpanBigger">,</span> or my ride is really dirty<span className="SpanBigger">,</span> and I decided to get a car
        wash
      </>
    ),
    Answer: (
      <>
        If you rarely wash or have adherent dirt on your vehicle, we recommend a <span className="ArtcilePageFontWeight"> Self-Service Bay </span> to produce
        the cleanest ride! This is because it offers the “human” element. Your vehicle cleaning is hand crafted to your liking! You can spend more time on areas
        that are dirtier than others unlike any machine that cleans all portions of the vehicle equally. Our self-service bays offer all the high-end carwash
        chemicals available in the industry. Most of these chemicals are applied “touch-free” with our wands and then rinsed off with a high presser wash rinse.
        That being said, we do offer a single hog’s hair brush in our bays but we recommend to customers to always rinse the brush off with the high pressure
        rinse before using them to avoid the chance of a micro scratch from a prior customer leaving it dirty. A hog’s hair brush has expensive natural bristles
        that cannot harm or scratch a vehicle finish if used when it's clean. The reason some express tunnel car washes can scratch the finish of a vehicle is
        because the “dirty” vehicle ahead of you runs through the tunnel and then some of that dirt is rubbed off on to the next vehicle
        <span className="SpanBigger">,</span> causing micro scratches.
      </>
    ),
  },
  //
  {
    Question: (
      <>
        <div className="ArtcilePage1F3Q">Self-Service Pros:</div>
      </>
    ),
    Answer: (
      <>
        <ul>
          <li> Guaranteed to produce a clean vehicle to your liking.</li>
          <li> Relatively inexpensive.</li>
          <li>
            {" "}
            Can be fun! We have families (with kids) that make an event out of washing their cars at our facilities hence our slogan “We want to make car
            washing Fun Again!”
          </li>
        </ul>
      </>
    ),
  },
  //
  {
    Question: (
      <>
        <div className="ArtcilePage1F3Q">Self Service Cons:</div>
      </>
    ),
    Answer: (
      <>
        <ul>
          <li>Slightly more time consuming and you may break a sweat in Texas!</li>
          <li>
            If you are not used to using a pressure washer<span className="SpanBigger">,</span> you may get a little wet which can be nice in warm weather
            <span className="SpanBigger">,</span> but unpleasant in the winter.
          </li>
        </ul>
      </>
    ),
  },
  {
    Question: <>2. I wash my ride regularly (at least every week)</>,
    Answer: (
      <>
        If you wash regularly<span className="SpanBigger">,</span> you can either use our self-service bay or our Touch-Free Automatic. In Keller, we have a
        Laser Wash. In Grapevine
        <span className="SpanBigger">,</span> we have a Kondor. A touch-free machine is the best way to maintain a clean vehicle WITHOUT risking damage to a
        vehicle's finish. High-end car manufacturers like Tesla only recommend touchless car washes only! Here is the link to the owner's manual <span> </span>
        <a href="https://www.tesla.com/ownersmanual/model3/en_us/GUID-65384C1F-86F2-44E8-A8BC-8A12E7E00A40.html">Check Url</a> . The manufacturer states…
        <br />
        <br />
        <br />
        <img src={ArticleOneImg1} alt="" className="ArticleOneImg1" />
        <br />
        <br />
        <br />
        Our touch-free equipment does not offer caustic solutions or chemicals above a pH of 13.
        <br />
        Can a touch-free automatic clean a vehicle well? <span className="ArtcilePageFontWeight">Absolutely</span>
        <span className="SpanBigger">,</span> if you are maintaining a clean car! This is analogous to personal hygiene and showering on a regular basis. Most
        of us can remain “clean” and hygienic by showering daily. However<span className="SpanBigger">,</span> if we decide to only shower once a month
        <span className="SpanBigger">,</span> showering alone will not cut it and we will have to introduce a bath or scrub brush in the equation! ☺.
        <br />
        <br />
        Now<span className="SpanBigger">,</span> if I haven’t washed in a while or have adherent dirt on my vehicle can I still get a clean car with a
        touch-free automatic? Still <span className="ArtcilePageFontWeight">Absolutely</span>
        <span className="SpanBigger">,</span> with a little assistance from a microfiber towel! In these situations<span className="SpanBigger">,</span> we
        recommend getting one of our top washes and to spot dry clean your vehicle immediately after driving through the touch-free carwash. Please only use a{" "}
        <span className="ArtcilePageUnderLine">clean</span> <span></span>
        microfiber towel. This is best done <span className="ArtcilePageUnderLine">immediately</span> <span></span> after the wash while any left-over dirt is
        soft from the chemicals and rinse. You can bring your own microfiber towel or purchase one from our vending machine.
        <br />
        <br />
        <br />
        <img src={ArticleOneImg2} alt="" className="ArticleOneImg2" />
        <br />
        <br />
        <br />
        <span className="ArtcilePageUnderLine">Touch-Free Pros:</span>
        <br />
        <br />
        <ul>
          <li>Fast and automated.</li>
          <li>Does not risk a scratch or damage to paint finish or trim.</li>
        </ul>
        <br />
        <br />
        <span className="ArtcilePageUnderLine">Touch-Free Cons:</span>
        <br />
        <br />
        <ul>
          <li>
            No friction so not the best option for vehicles that don’t wash regularly or have adherent dirt UNLESS you spot dry clean your car immediately after
            a touch-free wash.
          </li>
        </ul>
        <br />
        If you ever have any questions or concerns<span className="SpanBigger">,</span> please reach out to us at the Contact Us tab of the website or feel free
        to shoot us a text at 817-380-8460. Happy Washing!
        <br />
        <br />
        -WashYourStuff-
      </>
    ),
  },
  {
    Question: <></>,
    Answer: (
      <>
        <SharePost />
      </>
    ),
  },
];

const RelatedArticlesData = [...BlogData2, ...BlogData3];

export default function ArticlePage1() {
  return (
    <div className="ArticlePage">
      <Article ArticleData={BlogData1} QuestionsData={QuestionsData1} RelatedArticlesData={RelatedArticlesData} />
    </div>
  );
}
