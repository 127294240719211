import React, { useState, useEffect } from "react";
import "./SmallCartMob.css";
import RightMark from "../../../Assets/svg/Branches/RightMark.svg";
import OpenIcon from "../../../Assets/svg/Branches/OpenIcon.svg";
import CloseIcon from "../../../Assets/svg/Branches/CloseIcon.svg";

export default function SmallCartMob({ serviceTitle, price, leftItems, imageSrc, imageAlt, heightMob }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [computedHeight, setComputedHeight] = useState(heightMob || "100%");

  useEffect(() => {
    const updateHeight = () => {
      if (window.innerWidth <= 575) {
        setComputedHeight(heightMob || "100%");
      }
    };

    updateHeight(); // Initial check for height
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, [heightMob]); // Only watch heightMob

  // Toggle expanded state and set height to auto when expanded
  const handleExpand = () => {
    setIsExpanded(true);
    setComputedHeight("auto"); // Set height to auto on expansion
  };

  const handleCollapse = () => {
    setIsExpanded(false);
    setComputedHeight(heightMob || "32vh"); // Restore original height when collapsed
  };
  return (
    <div className="SmallCartContainerMob">
      <div className="SmallCartMobS2">
        <div className="SmallCartMobS2P1" style={{ height: computedHeight }}>
          <div className="SmallCartMobS2P1Head">
            <div className="SmallCartMobS2P1HeadL1">{serviceTitle}</div>
            <div className="SmallCartMobS2P1HeadL2">{price}</div>
            <div className="SmallCartMobS2P1HeadL3Border"></div>
          </div>
          <div className="SmallCartMobS2P1Items">
            <div className="SmallCartMobS2P1ItemsL">
              {leftItems.slice(0, 3).map((item, index) => (
                <div key={index} className="SmallCItemMob">
                  <img src={RightMark} alt="" className="SmallCItemMobImg" />
                  <div className="SmallCItemMobText">{item}</div>
                </div>
              ))}
              {!isExpanded && (
                <>
                  <div className="ViewAllCont">
                    <div className="ShadowSCMob"></div>
                    <button className="ViewAllButtonMob" onClick={handleExpand}>
                      View All <img src={OpenIcon} alt="" />
                    </button>
                  </div>
                </>
              )}
              {isExpanded &&
                leftItems.slice(3).map((item, index) => (
                  <div key={index + 3} className="SmallCItemMob">
                    <img src={RightMark} alt="" className="SmallCItemMobImg" />
                    <div className="SmallCItemMobText">{item}</div>
                  </div>
                ))}
            </div>
            {isExpanded && (
              <button className="CloseButtonMob" onClick={handleCollapse}>
                View Less <img src={CloseIcon} alt="" />
              </button>
            )}
          </div>
        </div>
        <div className="SmallCartMobS2P2">
          <img src={imageSrc} alt={imageAlt} className="SmallCartMobS2P2Img" />
        </div>
      </div>
    </div>
  );
}
