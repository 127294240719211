import React from "react";
import "./Article.css";
import Article from "../../Assets/hooks/Reuse_Component/ArticleComponent/ArticleComponent";
import { BlogData1 } from "../Blog/Blog";
import { BlogData2 } from "../Blog/Blog";
import { BlogData3 } from "../Blog/Blog";
import "./Article.css";
import SharePost from "../../Component/SharePost's/SharePost";
const QuestionsData1 = [
  {
    Question: (
      <>
        {" "}
        Bathing your dog(s) is an essential part of keeping them healthy. That said<span className="SpanBigger">,</span> sometimes this can be a little
        overwhelming and stressful. That’s where having a dog grooming bathtub can be of assistance.
        <br />
        <br />
        Professional dog grooming tubs can help wash your dog(s) with less stress on you and your dog. They can help you and your dog feel more comfortable at
        bath time. LET'S look at the benefits:
      </>
    ),
    Answer: <></>,
  },
  {
    Question: (
      <>
        <span className="">Designed For Pets</span>
      </>
    ),
    Answer: (
      <>
        A professional dog grooming bathtub is built specifically for dog washing…
        <br />
        <br />
        <ul>
          <li>Correct ergonomic height allowing you to stand as your pet bathes without you taking a bath with your pet!</li>
          <li>Access to both sides of your pet so that you can clean your dogs right and left sides equally as well.</li>
          <li>Dog can remain tied down and secure throughout the bath.</li>
          <li>Includes pet grooming vacuum to suck away any shedding hair.</li>
          <li>Includes pet dryer that allows air drying those hard to reach areas.</li>
          <li>Improved drainage to rinse off soap suds and make bath time quicker.</li>
          <li>Less clean up after your dog is nice and clean!</li>
        </ul>
      </>
    ),
  },
  {
    Question: (
      <>
        <span className="">Better For Your Back</span>
      </>
    ),
    Answer: (
      <>
        <ul>
          <li>
            When bathing your dog<span className="SpanBigger">,</span> it’s important to consider your own safety.
          </li>
          <li>Professional dog grooming bathtubs have ramps to reduce the need of lifting your dog (particularly important for larger dogs)</li>
          <li>Correct ergonomic height allows you to wash your dog without leaning forward in awkward positions risking a back sprain.</li>
        </ul>
      </>
    ),
  },
  {
    Question: (
      <>
        <span className="">Safety For Your Dog</span>
      </>
    ),
    Answer: (
      <>
        <ul>
          <li>
            Professional dog grooming tubs offer tether hooks to help keep pets secure so that they don’t attempt to run or jump in a slippery environment.
          </li>
        </ul>
      </>
    ),
  },
  {
    Question: <></>,
    Answer: (
      <>
        <SharePost />
      </>
    ),
  },
];

const RelatedArticlesData = [...BlogData1, ...BlogData3];

export default function ArticlePage2() {
  return (
    <div className="ArticlePage">
      <Article ArticleData={BlogData2} QuestionsData={QuestionsData1} RelatedArticlesData={RelatedArticlesData} />
    </div>
  );
}
