import React from "react";
import "./SliderBranches.css";

export default function SliderBranches({ title, subtitle, images }) {
  return (
    <div className="Slider_Branches">
      <div className="Slider_Branches_Text">
        <div className="Slider_B_Head">{title}</div>
        <div className="Slider_B_Head2">{subtitle}</div>
      </div>
      <div className="Slider_Img_Container">
        <div className="Slider_Branches_Track">
          {images.map((image, index) => (
            <div key={index} className="Slider_Img_Item">
              <img src={image.url} alt={image.alt} className="Slider_Branches_Img" />
            </div>
          ))}
          {images.map((image, index) => (
            <div key={index + images.length} className="Slider_Img_Item">
              <img src={image.url} alt={image.alt} className="Slider_Branches_Img" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
