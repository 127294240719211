import React from "react";
import "../Branches.css";
import GrandS1Img from "../../../Assets/images/Branches/GrandPrairie/Grand_PrairieMain.jpg";
import BigCart from "../../../Component/Branches/BigCart/BigCart";
import BigCartMob from "../../../Component/Branches/BigCart/BigCartMob";
import SmallCart from "../../../Component/Branches/SmallCart/SmallCart";
import SmallCartMob from "../../../Component/Branches/SmallCart/SmallCartMob";
import { Link } from "react-router-dom";
import ButtonIcon from "../../../Assets/svg/Header/Button_Icon.svg";
import SliderBranches from "../../../Component/Branches/SliderBranches/SliderBranches";
import DisplayBranches from "../../../Component/Branches/DisplayBranches/DisplayBranches";
import Keller from "../../../Assets/images/Branches/Keller.jpg";
import Grapevine from "../../../Assets/images/Branches/Grapevine.jpg";
import GrandPrairieSlider1 from "../../../Assets/images/Branches/GrandPrairie/GrandPrairieSlider1.jpg";
import GrandPrairieSlider2 from "../../../Assets/images/Branches/GrandPrairie/GrandPrairieSlider2.jpg";
import GrandPrairieSlider3 from "../../../Assets/images/Branches/GrandPrairie/GrandPrairieSlider3.jpg";
import GrandPrairieSlider4 from "../../../Assets/images/Branches/GrandPrairie/GrandPrairieSlider4.jpg";
import GrandPrairieSlider5 from "../../../Assets/images/Branches/GrandPrairie/GrandPrairieSlider5.jpg";
import GrandPrairieSlider6 from "../../../Assets/images/Branches/GrandPrairie/GrandPrairieSlider6.jpg";
import GrandPrairieSlider7 from "../../../Assets/images/Branches/GrandPrairie/GrandPrairieSlider7.jpg";
import GrandPrairieSlider8 from "../../../Assets/images/Branches/GrandPrairie/GrandPrairieSlider8.jpg";
import GrandPrairieSlider9 from "../../../Assets/images/Branches/GrandPrairie/GrandPrairieSlider9.jpg";
import GrandPrairieSlider10 from "../../../Assets/images/Branches/GrandPrairie/GrandPrairieSlider10.jpg";
import GrandPrairieSlider11 from "../../../Assets/images/Branches/GrandPrairie/GrandPrairieSlider11.jpg";
import GrandPrairieSlider12 from "../../../Assets/images/Branches/GrandPrairie/GrandPrairieSlider12.jpg";
import { grandPrairieDataBigCart } from "../ServicesData/Grand_PrairieData";
import { grandPrairieSmallCartsData } from "../ServicesData/Grand_PrairieData";
export default function GrandPrairieContent() {
  return (
    <>
      <div className="BranchMainContainer">
        <BranchMainS1 />
        <BranchMainS2 />
        <BranchMainS3 />
        <BranchMainS4 />
        <BranchMainS5 />
      </div>
    </>
  );
}

function BranchMainS1() {
  return (
    <>
      <div className="BranchMainS1">
        <div className="BranchMainS1P1">Welcome to Our </div>
        <div className="BranchMainS1P1_2">NEW LOCATION</div>
        <div className="BranchMainS1P2">grand prairie </div>
        <div className="BranchMainS1P3">
          <img src={GrandS1Img} alt="Grand Prairie car wash service senter gp" className="BranchMainS1P3Img" />
        </div>
      </div>
    </>
  );
}
function BranchMainS2() {
  return (
    <>
      {/* Big Cards For Web */}
      <div className="BranchMainS2">
        <BigCart {...grandPrairieDataBigCart} />
      </div>
      {/*  */}
      {/* Big Cards For Mob */}
      {/*  */}
      <div className="BranchMainS2Mob">
        <BigCartMob {...grandPrairieDataBigCart} />
      </div>
    </>
  );
}
function BranchMainS3() {
  return (
    <>
      <div className="BranchMainS3">
        {/* Small Cards For Web */}
        <div className="BranchMainS3P1">
          {grandPrairieSmallCartsData.map((cartData, index) => (
            <SmallCart
              key={index}
              serviceTitle={cartData.serviceTitle}
              price={cartData.price}
              leftItems={cartData.leftItems}
              imageSrc={cartData.imageSrc}
              imageAlt={cartData.imageAlt}
              height={cartData.height}
            />
          ))}
        </div>
        {/*  */}
        {/*Small Cards  For Mob */}
        <div className="BranchMainS3P1Mob">
          {grandPrairieSmallCartsData.map((cartData, index) => (
            <SmallCartMob
              key={index}
              serviceTitle={cartData.serviceTitle}
              price={cartData.price}
              leftItems={cartData.leftItems}
              imageSrc={cartData.imageSrc}
              imageAlt={cartData.imageAlt}
              height={cartData.height}
              heightMob={cartData.heightMob}
            />
          ))}
        </div>
        {/*  */}
        <div className="BranchesGetButtonMain">
          <Link to="/Locations">
            <button className="BranchesGetButton">
              <img src={ButtonIcon} alt="" className="BranchesGetButtonImg" />
              Get Direction
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

const images = [
  { url: GrandPrairieSlider1, alt: "grand prairie self service car wash service" },
  { url: GrandPrairieSlider2, alt: "grand prairie car wash" },
  { url: GrandPrairieSlider3, alt: "grand prairie huge car wash" },
  { url: GrandPrairieSlider4, alt: "grand prairie carpet wash" },
  { url: GrandPrairieSlider5, alt: "grand prairie car wash automatic service" },
  { url: GrandPrairieSlider6, alt: "grand prairie self car wash service" },
  { url: GrandPrairieSlider7, alt: "grand prairie self service car wash" },
  { url: GrandPrairieSlider8, alt: "grand prairie automatic car wash" },
  { url: GrandPrairieSlider9, alt: "grand prairie car cleaning service" },
  { url: GrandPrairieSlider10, alt: "grand prairie car wash service" },
  { url: GrandPrairieSlider11, alt: "grand prairie touch free bay" },
  { url: GrandPrairieSlider12, alt: "grand prairie touch free car wash" },
];

function BranchMainS4() {
  return (
    <>
      <SliderBranches title="Grand Prairie Photos" subtitle="The Perfect Car Wash" images={images} />
    </>
  );
}

function BranchMainS5() {
  return (
    <>
      <div className="BranchMainS5">
        <div className="BranchMainS5P1">Other location</div>
        <div className="BranchMainS5P2">
          <DisplayBranches images={Grapevine} Alt="Grapevine car wash service" BranchName="Grapevine" Url={`/Grapevine`} ButtonTitle="Grapevine Services" />
          <DisplayBranches images={Keller} Alt="Keller car wash service" BranchName="Keller" Url={`/Keller`} ButtonTitle="Keller Services" />
        </div>
      </div>
    </>
  );
}
