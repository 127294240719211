import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import HeaderHomeLogo from "../../Assets/svg/Header/LogoScroll.svg";
import ButtonIcon from "../../Assets/svg/Header/Button_Icon.svg";
import WashClub from "../../Assets/svg/Header/WashClub.svg";
export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const currentPath = window.location.pathname;

  const isActive = (path) => currentPath === path;

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = window.innerHeight * 0.95;
      if (window.scrollY > scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="HeaderWeb_Absoulte">
        <div className="HeaderWeb">
          <div>
            <Link to="/">
              <img src={HeaderHomeLogo} alt="" className="HeaderWebImg" />
            </Link>
          </div>
          <div className="HeaderRoute">
            <Link to="/" className={`${isActive("/") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              Home
            </Link>
            <Link to="/About-us" className={`${isActive("/About-us") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              About Us
            </Link>
            <Link to="/Services" className={`${isActive("/Services") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              Services
            </Link>
            <Link to="/faqs" className={`${isActive("/faqs") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              FAQs
            </Link>
            <Link to="/blog" className={`${isActive("/blog") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              Blog
            </Link>
            <Link to="/WashClub" className={`${isActive("/WashClub") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              <div className="WashClubCont">
                WashClub <img src={WashClub} alt="" className="WashClubSvg" />
              </div>
            </Link>
            <Link to="/Contact-us" className={`${isActive("/Contact-us") ? "active_link" : ""} ${isScrolled ? "scrolled_link" : ""}`}>
              Contact us
            </Link>
            <Link to="/Locations">
              <button className="HeaderButton">
                <img src={ButtonIcon} alt="" className="HeaderButtonImg" />
                Our Locations
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
