import React from "react";
import "./ArticleComponent.css";
import { Link } from "react-router-dom";
import LeftIcon from "../../../svg/Blog/ArticleIconBack.svg";
import RightIcon from "../../../svg/Home/RightIcon.svg";

export default function Article({ ArticleData, QuestionsData, RelatedArticlesData }) {
  return (
    <div className="ArticlePageContainer">
      {/* Main Article */}
      <div className="ArticleS1Container">
        {ArticleData.map((article, index) => (
          <>
            {/*  */}
            <div className="ArticleS1P1">
              <div className="ArticleS1P1Button">
                <Link to="/Blog">
                  <img src={LeftIcon} alt="" className="ArticleS1P1Icon" />
                  Back
                </Link>
              </div>
            </div>
            {/*  */}
            <div key={index} className="ArticleS1P2">
              <div className="ArticleS1P2L1">Blog Article</div>
              <div className="ArticleS1P2L2">{article.Date}</div>
              <div className="ArticleS1P2L3">{article.Head}</div>
              <div className="ArticleS1P2L4">{article.CreatedBy}</div>
              <div className="ArticleS1P2L5">
                <img src={article.Image} alt={article.Head} className="ArticleS1P2L5Img" />
              </div>
            </div>
          </>
        ))}
      </div>

      {/* Questions Section */}
      <div className="ArticleS2">
        {QuestionsData.map((question, index) => (
          <div key={index} className="ArticleS2">
            <div className="ArticleS2P1">{question.Question}</div>
            <div className="ArticleS2P2">{question.Answer}</div>
          </div>
        ))}
      </div>

      {/* Related Articles Section */}
      <div className="ArticleS3Container">
        <div className="ArticleS3Head">Related articles</div>
        <div className="ArticleS3">
          {RelatedArticlesData.map((related, index) => (
            <>
              <div>
                <div className="Blog_Box_Container">
                  {/*  */}
                  <div className="BlogBoxP1">
                    <Link to={related.ImgUrl}>
                      <img src={related.Image} alt={related.Alt} className="BlogBoxP1Img" />
                    </Link>
                  </div>
                  {/*  */}
                  <div className="BlogBoxP2">{related.Date}</div>
                  {/*  */}
                  <div className="BlogBoxP3">{related.Head}</div>

                  {/*  */}
                  <div className="BlogBoxP4">{related.Text}</div>

                  {/*  */}
                </div>
                <div className="BlogBoxP5">
                  <Link to={related.Button}>
                    Read More <img src={RightIcon} alt="" className="BlogBoxP5Icon" />
                  </Link>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
